@import url("//fast.fonts.net/cssapi/1610e411-8f47-449f-b00d-2c8b82f44ebd.css"); /*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/* Basic Reset for all elements */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

/*** Font/Text Resets ***/
br:nth-last-child(1) {
  display: none;
}

svg {
  width: 100%;
  height: auto;
}

input[type=text],
input[type=date],
input[type=datetime-local],
textarea {
  cursor: text;
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Prevent Selections ***/
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

div::-webkit-scrollbar-track {
  background-color: transparent;
}

div::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

div::-webkit-scrollbar-thumb {
  background-color: #616771;
}

div::-webkit-scrollbar-corner {
  background: #616771;
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/** Default font styles **/
html,
body {
  color: #616771;
  font-family: "Gilroy W05 Regular", sans-serif;
  font-size: 16px;
  font-weight: "Gilroy W05 Light", sans-serif;
  line-height: 1.6;
  letter-spacing: 0.02em;
  width: 100%;
}
@media (max-width: 979px) {
  html,
body {
    font-size: 14px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  color: inherit;
}

/** Font Classes **/
.heading, .component-heading, .subheading, .page-heading {
  font-weight: "Gilroy W05 Medium", sans-serif;
}

.page-heading {
  font-size: 2.441rem;
}

.subheading {
  font-size: 1.563rem;
}

.component-heading {
  font-size: 1.125rem;
}

.component-title {
  font-size: 1.563rem;
  color: #33313f;
  line-height: 1.24em;
  font-family: "Gilroy W05 Medium", sans-serif;
}

.description-text {
  font-size: 0.875rem;
}

.small-text {
  font-size: 0.64rem;
}
.small-text.--bold {
  font-weight: "Gilroy W05 Regular", sans-serif;
}

.super-script {
  font-size: 0.512rem;
}

/** Text Color Modifiers **/
.--warning {
  color: #d83a52;
}

.--success {
  color: #d83a52;
}

.--light {
  color: white;
}

.--dark {
  color: #616771;
}

/** Weight Modifiers **/
.--light {
  font-weight: "Gilroy W05 Light", sans-serif;
}

.--regular {
  font-weight: "Gilroy W05 Regular", sans-serif;
}

.--medium {
  font-weight: "Gilroy W05 Medium", sans-serif;
}

.--bold {
  font-weight: "Gilroy W05 Bold", sans-serif;
}

.--x-bold {
  font-weight: "Gilroy W05 Heavy", sans-serif;
}

/*** Spacing ***/
.--sb-margin {
  margin-bottom: 0.375rem;
}

.--rb-margin {
  margin-bottom: 0.5rem;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-toggle_switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  cursor: pointer;
}
.ovis-toggle_thumb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}
.ovis-toggle_thumb:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.ovis-toggle.--small .ovis-toggle_thumb:before {
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 2px;
}
.ovis-toggle.--small .ovis-toggle_switch {
  height: 14px;
  width: 30px;
}
.ovis-toggle.--small.--active .ovis-toggle_thumb::before {
  transform: translateX(16px);
}
.ovis-toggle.--active .ovis-toggle_thumb {
  background-color: #0150b5;
}
.ovis-toggle.--active .ovis-toggle_thumb::before {
  transform: translateX(23px);
}

.ovis-input_instance {
  width: 100%;
}
.ovis-input_container {
  width: 100%;
  background-color: white;
  border-radius: 6px;
  border: none;
  display: flex;
}
.ovis-input_container.--inverted {
  background: #f5f7fb;
}
.ovis-input_container.--inverted input {
  background: #f5f7fb;
}
.ovis-input_container.--outlined {
  border: 1px solid #9c9eac;
}
.ovis-input_container.--small {
  padding: 0.5rem 1rem;
}
.ovis-input_container.--small input,
.ovis-input_container.--small input::placeholder {
  font-size: 0.8rem;
}
.ovis-input_container.--medium {
  padding: 0.43rem 1rem;
}
.ovis-input_container.--medium input,
.ovis-input_container.--medium input::placeholder {
  font-size: 1rem;
}
.ovis-input_container.--large {
  padding: 1rem;
}
.ovis-input_container.--large input,
.ovis-input_container.--large input::placeholder {
  font-size: 1.15rem;
}
.ovis-input_container.--with-prefix-icon {
  padding-left: 0;
}
.ovis-input_container.--with-addonBefore {
  padding-left: 0;
}
.ovis-input_container.--with-addonBefore input {
  padding-left: 0.375rem;
}
.ovis-input_container.--with-addonAfter {
  padding-right: 0;
}
.ovis-input_container.--with-addonAfter input {
  padding-right: 0.375rem;
}
.ovis-input_container.--val-visuals .field-valid-indicator {
  position: relative;
  height: 100%;
}
.ovis-input_container.--val-visuals .field-valid-indicator::before {
  position: absolute;
  right: -20px;
  top: -5px;
}
.ovis-input_container.--val-visuals.--invalid {
  border: 1px solid #d83a52;
}
.ovis-input_container.--val-visuals:invalid.--invalid {
  border: 1px solid #d83a52;
}
.ovis-input_container.--clearable input {
  padding-right: 0.5rem;
}
.ovis-input_prefix, .ovis-input_suffix {
  position: relative;
  padding: 0 11px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  border-right: 1px solid #d9d9d9;
  transition: all 0.3s;
  height: calc(100% + 0.641rem + 0.641rem);
  color: #33313f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ovis-input_suffix {
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ovis-input_icon {
  width: 40px;
  padding: 0 0.375rem;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ovis-input_icon svg {
  width: 100%;
}

.clear-input {
  height: 15px;
  width: 15px;
  color: white;
  opacity: 1;
  position: absolute;
  top: 50%;
  right: 5px;
  z-index: 1;
  cursor: pointer;
  transition: color 0.3s ease, opacity 0.15s ease;
  user-select: none;
  background: #d83a52;
  border-radius: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.clear-input:hover {
  opacity: 0.8;
}
.clear-input svg {
  width: 15px;
  height: 15px;
  fill: white;
}

.ovis-input {
  font-family: "Gilroy W05 Regular", sans-serif;
  font-weight: 400;
  color: #616771;
  border: none;
  width: 100%;
  outline: none;
}
.ovis-input.--clearable {
  padding-right: 1.4rem;
}
.ovis-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 300;
  opacity: 1; /* Firefox */
  color: rgba(156, 158, 172, 0.5019607843);
  font-size: 1rem;
}

input[type=text]:disabled,
input[type=email]:disabled,
input[type=url]:disabled,
input[type=tel]:disabled,
input[type=date]:disabled,
input[type=time]:disabled,
input[type=datetime-local]:disabled,
textarea:disabled {
  opacity: 0.7;
}
input[type=text]:-webkit-autofill::first-line,
input[type=email]:-webkit-autofill::first-line,
input[type=url]:-webkit-autofill::first-line,
input[type=tel]:-webkit-autofill::first-line,
input[type=date]:-webkit-autofill::first-line,
input[type=time]:-webkit-autofill::first-line,
input[type=datetime-local]:-webkit-autofill::first-line,
textarea:-webkit-autofill::first-line {
  font-size: 1rem;
  color: inherit;
  font-family: inherit;
}

textarea {
  padding: 0.5rem 0.5rem;
  min-height: 114px;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-checkbox {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
}
.ovis-checkbox > span {
  margin-right: 8px;
}
.ovis-checkbox > input {
  display: none;
}
.ovis-checkbox.modified {
  font-size: 20px;
  color: red;
}
.ovis-checkbox .label-text {
  width: auto;
  max-width: 8.5rem;
  text-align: left;
  font-size: "Gilroy W05 Regular", sans-serif;
}
.ovis-checkbox .required {
  color: red;
  margin-left: 1px;
}
.ovis-checkbox .checkbox-span {
  transition: 300ms;
  display: inline-block;
  min-width: 16px;
  height: 16px;
  margin-bottom: 1px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background: white;
}
.ovis-checkbox .checkbox-span.hover {
  transition: 300ms;
  border: 1px solid rgba(51, 49, 63, 0.6980392157);
}
.ovis-checkbox .checkbox-span .checkbox-svg {
  margin-bottom: 6.5px;
  display: none;
}
.ovis-checkbox .checkbox-span .checkbox-svg.checked {
  display: block;
}
.ovis-checkbox .checkbox-span .checkbox-svg .svg-rectangle {
  fill: #616771;
}
.ovis-checkbox .checkbox-span .checkbox-svg .svg-line {
  stroke: white;
  stroke-width: 2;
  fill: none;
}
.ovis-checkbox.disabled .label-text {
  color: #616771;
}
.ovis-checkbox.disabled .checkbox-span {
  background-color: lightgray;
  border: 1px solid #d9d9d9 !important;
}
.ovis-checkbox.disabled .checkbox-span .checkbox-svg .svg-rectangle {
  fill: #616771;
}

.ovis-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.ovis-checkbox-group .ovis-checkbox {
  display: flex;
  width: 33%;
}
.ovis-checkbox-group .ovis-checkbox .labelText {
  display: flex;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.react-dropdown-select {
  padding: 0.3rem;
}

.ovis-bp-select {
  width: 100%;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-text-area {
  display: grid;
  width: 100%;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  border: none;
  display: flex;
}
.ovis-text-area::after {
  content: attr(data-replicated-value) " ";
  visibility: hidden;
  grid-area: 1/1/1/1;
}
.ovis-text-area_input, .ovis-text-area::after {
  padding: 0.5rem;
  font-size: 0.89rem;
}
.ovis-text-area_input {
  border-radius: 6px;
  grid-area: 1/1/2/2;
  width: 100%;
  font-family: "Gilroy W05 Regular", sans-serif;
  font-size: 14.24px;
  font-weight: 300;
  background-color: white;
  overflow: hidden;
}
.ovis-text-area_input:focus-within {
  outline: 1px solid #616771;
  border-radius: none;
}
.ovis-text-area_input:focus {
  outline: none !important;
}
.ovis-text-area_input.--no-height {
  height: initial;
  min-height: initial;
}
.ovis-text-area_input.--warning {
  outline: 2px solid #d83a52;
  border-radius: none;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ovis-loader {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 3px solid #003171;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 1s cubic-bezier(0.9, 0.01, 0.01, 0.75) infinite; /* Safari */
  animation: spin 1s cubic-bezier(0.9, 0.2, 0.2, 0.75) infinite;
}
.ovis-loader.large {
  border: 7px solid rgba(0, 0, 0, 0.1);
  border-top: 7px solid #003171;
  width: 50px;
  height: 50px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  position: absolute;
  top: 50%;
  left: 40%;
  margin-left: 10%;
  transform: translate3d(-50%, -50%, 0);
}

.dot {
  width: 10px;
  height: 10px;
  background: #616771;
  border-radius: 100%;
  margin-right: 3px;
  display: inline-block;
  animation: slide 1s infinite;
}

.dot:nth-child(1) {
  animation-delay: calc(0.1s * 1);
}

.dot:nth-child(2) {
  animation-delay: calc(0.1s * 2);
}

.dot:nth-child(3) {
  animation-delay: calc(0.1s * 3);
}

.dot:nth-child(4) {
  animation-delay: calc(0.1s * 4);
}

@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
button {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.ovis-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  padding: 0.375rem 0.5rem;
  height: 2rem;
  letter-spacing: normal;
  font-weight: "Gilroy W05 Light", sans-serif;
  box-shadow: 0 2px rgba(0, 0, 0, 0.0431372549);
  font-size: inherit;
  line-height: 1.5715;
  white-space: nowrap;
  text-align: center;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  touch-action: manipulation;
}
.ovis-btn svg {
  height: 100%;
}
.ovis-btn_close {
  padding: 0;
}
.ovis-btn.--no-default-styles {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.ovis-btn.--default {
  background: transparent;
  color: #616771;
  border: 1px solid #616771;
}
.ovis-btn.--default:hover {
  color: #30a8d1;
  border-color: #30a8d1;
}
.ovis-btn.--default:hover svg {
  fill: #30a8d1;
}
.ovis-btn.--primary {
  background: #003171;
  color: white;
}
.ovis-btn.--primary svg {
  fill: white;
}
.ovis-btn.--primary:hover {
  background-color: #0150b5;
}
.ovis-btn.--primary.--disabled {
  background: #003171;
}
.ovis-btn.--secondary {
  background: #30a8d1;
  color: white;
}
.ovis-btn.--secondary svg {
  fill: white;
}
.ovis-btn.--secondary:hover {
  background-color: #2a98bd;
}
.ovis-btn.--secondary.--disabled {
  background: #30a8d1;
}
.ovis-btn.--warning {
  background: #d83a52;
  color: white;
}
.ovis-btn.--warning svg {
  fill: white;
}
.ovis-btn.--warning:hover {
  background-color: #b63546;
}
.ovis-btn.--warning.--disabled {
  background: #d83a52;
}
.ovis-btn.--text {
  color: #616771;
  box-shadow: none;
}
.ovis-btn.--text:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.ovis-btn.--text.--disabled {
  background-color: rgba(0, 0, 0, 0.05);
}
.ovis-btn.--link {
  background: white;
  color: #30a8d1;
  box-shadow: none;
}
.ovis-btn.--link:hover {
  color: #2a98bd;
}
.ovis-btn.--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.ovis-btn.--small {
  height: 24px;
  padding: 0 7px;
}
.ovis-btn.--large {
  width: 12rem;
  height: 2.7rem;
}
.ovis-btn.--fill-width {
  width: 100%;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-notification {
  transition: 300ms;
  background-color: red;
  transform: translate(400px);
  opacity: 0;
  color: black;
  padding: 16px 26px;
  position: relative;
  width: 384px;
  max-width: calc(100vw - 48px);
  margin-bottom: 16px;
  margin-left: auto;
  overflow: hidden;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  line-height: 1.5715;
  -webkit-box-shadow: 0 3px 6px -4px RGB(0 0 0/12%), 0 6px 16px 0 RGB(0 0 0/8%), 0 9px 28px 8px RGB(0 0 0/5%);
  box-shadow: 0 3px 6px -4px RGB(0 0 0/12%), 0 6px 16px 0 RGB(0 0 0/8%), 0 9px 28px 8px RGB(0 0 0/5%);
}
.ovis-notification.mounted {
  opacity: 1;
  z-index: 99999;
  transform: translate(0px);
}
.ovis-notification.unmounted {
  transition: opacity 100ms, transform 500ms;
  opacity: 0;
  transform: translate(0, -400px);
}
.ovis-notification .ovis-icon-message-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 3px;
}
.ovis-notification .ovis-icon-message-row .ovis-notification-icon {
  display: flex;
}
.ovis-notification .ovis-icon-message-row .ovis-notification-icon.--success {
  color: #8fdb8f;
  height: 23.39px;
}
.ovis-notification .ovis-icon-message-row .ovis-notification-icon.--error {
  color: #d83a52;
}
.ovis-notification .ovis-icon-message-row .ovis-notification-icon.--info {
  color: #0150b5;
}
.ovis-notification .ovis-icon-message-row .notification-message-container {
  display: flex;
  justify-content: flex-start;
  margin-left: 1.3rem;
  padding-right: 0.1rem;
  width: 350px;
}
.ovis-notification .ovis-icon-message-row .notification-message-container .notification-message {
  font-size: 17px;
  margin-top: -4px;
}
.ovis-notification .notification-description {
  display: flex;
  justify-content: flex-start;
  margin-left: 2.6rem;
  font-size: 14px;
  line-height: 1.3rem;
}

.notification-close-button {
  width: 20px;
  height: 20px;
  border: none;
  background-color: white;
  color: gray;
  outline: none;
  cursor: pointer;
}

.ovis-notification-parent {
  position: fixed;
  right: 0;
  width: 434px;
  padding: 25px;
  z-index: 9999999;
  pointer-events: none;
}

.ovis-notification-child {
  pointer-events: all;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

.ovis-file-upload {
  display: flex;
  transition: background-color 0.2s;
  width: 100%;
}
.ovis-file-upload_container {
  display: inline;
}
.ovis-file-upload_container .file-name {
  margin-top: 0.5rem;
}
.ovis-file-upload_container .file-name p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ovis-file-upload_container .file-name .clear-input {
  position: static;
  transform: none;
  margin-right: 0.5rem;
}
.ovis-file-upload.--disabled .ovis-input {
  background-color: #e8e8e8;
  cursor: default;
}
.ovis-file-upload.--disabled .ovis-btn {
  background-color: gray;
  cursor: default;
}
.ovis-file-upload:hover {
  background-color: #fafafa;
}
.ovis-file-upload:active {
  background-color: whitesmoke;
}
.ovis-file-upload .ovis-file-upload-btn {
  width: auto;
  transition: none;
  font-size: inherit;
}
.ovis-file-upload .ovis-file-upload-btn svg {
  width: 1.5rem;
  padding-right: 0.375rem;
  box-sizing: content-box;
}
.ovis-file-upload .ovis-file-upload-btn:hover {
  color: #30a8d1;
}
.ovis-file-upload .ovis-input {
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ovis-file-upload .ovis-input_instance {
  display: none;
}

.ovis-image-upload-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ovis-image-upload-container.--small {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ovis-image-upload-container.--small .ovis-input {
  font-size: 0.8rem;
}
.ovis-image-upload-container.white {
  background-color: white;
}
.ovis-image-upload-container.transparent {
  background-color: transparent;
}
.ovis-image-upload-container > i {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-image-upload-container .ovis-file-upload_container {
  display: none;
}

.ovis-image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0;
  height: 218px;
  width: 390px;
  background-color: white;
  border-radius: 2px;
}
.ovis-image-preview .ovis-image-preview_inner {
  height: 218px;
  overflow: hidden;
  width: 100%;
}
.ovis-image-preview .ovis-image-preview-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-position: center;
  background-size: cover;
}
.ovis-image-preview.--circle {
  border-radius: 100%;
  max-width: 218px;
}
.ovis-image-preview.--circle .ovis-image-preview_edit-btn {
  position: absolute;
  border-radius: 100%;
  right: 8%;
  bottom: 8%;
  height: 2.2rem;
  width: 2.2rem;
}
.ovis-image-preview.--circle .ovis-image-preview_inner {
  border-radius: 100%;
  text-align: center;
}
.ovis-image-preview.--small {
  height: 2rem;
  width: 90px;
  margin: 0 0.5rem 0 0;
  background-color: #fafafa;
}
.ovis-image-preview.--small img {
  cursor: pointer;
}
.ovis-image-preview img {
  border-radius: 2px;
  max-width: 100%;
}
.ovis-image-preview_edit-btn {
  position: absolute;
  right: 0.375rem;
  bottom: 0.375rem;
}

.ovis-image-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 218px;
  width: 390px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background-color: #fafafa;
  cursor: pointer;
}
.ovis-image-placeholder.--small {
  padding: 0.5rem;
}
.ovis-image-placeholder.--circle {
  border-radius: 50%;
}
.ovis-image-placeholder.--circle p {
  width: 80%;
  text-align: center;
  margin: auto;
}
.ovis-image-placeholder.--small {
  height: 2rem;
  font-size: 0.7rem;
  height: 100%;
  line-height: 10px;
  padding: 0.1rem;
  cursor: pointer;
}
.ovis-image-placeholder p {
  font-weight: "Gilroy W05 Medium", sans-serif;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-datepicker-container {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.ovis-datepicker-container .ovis-input_container {
  padding-left: 0;
}
.ovis-datepicker-container input {
  padding-left: 3rem;
}
.ovis-datepicker-container input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  position: absolute;
  left: 0;
  height: 100%;
  padding: 12px 0.375rem 0;
  width: 1.5rem;
  background-color: #fafafa;
  border-right: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.ovis-datepicker-container input::-webkit-calendar-picker-indicator:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.ovis-datepicker-container .clear-input {
  right: 0.5rem;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-radio {
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
}
.ovis-radio > span {
  margin-right: 8px;
}
.ovis-radio > input {
  display: none;
}
.ovis-radio.modified {
  font-size: 20px;
  color: red;
}
.ovis-radio .label-text {
  top: -4.5px;
  width: auto;
  max-width: 8.5rem;
  line-height: 24px;
  text-align: left;
}
.ovis-radio .required {
  color: red;
  margin-left: 1px;
}
.ovis-radio .radio-span {
  transition: 300ms;
  display: inline-block;
  min-width: 16px;
  height: 16px;
  margin-bottom: 1px;
  border-radius: 50%;
  border: 1px solid #d9d9d9;
  background-color: white;
}
.ovis-radio .radio-span.hover {
  transition: 300ms;
  border: 1px solid rgba(51, 49, 63, 0.6980392157);
}
.ovis-radio .radio-span .radio-svg {
  margin-bottom: 6.5px;
  display: none;
}
.ovis-radio .radio-span .radio-svg.checked {
  display: block;
}
.ovis-radio .radio-span .radio-svg .svg-circle {
  fill: #616771;
}
.ovis-radio .radio-span .radio-svg .svg-line {
  stroke: white;
  stroke-width: 2;
  fill: none;
}
.ovis-radio.disabled .label-text {
  color: #616771;
}
.ovis-radio.disabled .radio-span {
  background-color: #616771;
}

.ovis-radio-group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.ovis-radio-group .ovis-radio {
  display: flex;
  width: 33%;
}
.ovis-radio-group .ovis-radio .labelText {
  display: flex;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-slide-container {
  width: 100%;
}
.ovis-slide-container .ovis-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 0.3rem;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.ovis-slide-container .ovis-slider:hover {
  opacity: 1;
}
.ovis-slide-container .ovis-slider:hover.--disabled {
  opacity: 0.7;
}
.ovis-slide-container .ovis-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  background: #0150b5;
  cursor: pointer;
}
.ovis-slide-container .ovis-slider.--disabled::-webkit-slider-thumb {
  background: gray;
}
.ovis-slide-container .ovis-slider::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #4eaad5;
  cursor: pointer;
}
.ovis-slide-container .ovis-slider.--disabled::-moz-range-thumb {
  background: gray;
}
.ovis-slide-container .ovis-sliderticks {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
}
.ovis-slide-container .ovis-sliderticks p {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 2px;
  background: #d3d3d3;
  height: 10px;
  line-height: 2.7rem;
  margin: 0 0 1rem 0;
}
.ovis-slide-container .ovis-sliderticks p.--disabled {
  background: gray;
}
.ovis-slide-container .required {
  color: red;
  margin-left: 1px;
}
.ovis-slide-container .tick-number {
  color: #616771;
}
.ovis-slide-container .tick-number.--disabled {
  color: gray;
}
.ovis-slide-container.--disabled .slider {
  background: gray;
}
.ovis-slide-container.--disabled .ovis-slider:hover {
  opacity: 0.7;
}
.ovis-slide-container.--disabled .ovis-slider::-webkit-slider-thumb {
  background: gray;
}
.ovis-slide-container.--disabled .ovis-sliderticks p {
  background: gray;
}
.ovis-slide-container.--disabled .tick-number {
  color: gray;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

.ovis-drag-drop_zone {
  width: 100%;
  min-height: 18rem;
  max-height: 30rem;
  background-color: #fafafa;
  border-radius: 4px;
  border: 1px dashed #30a8d1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 1rem;
}
.ovis-drag-drop_zone > * {
  margin-bottom: 1.5rem;
}
.ovis-drag-drop_zone:hover {
  cursor: pointer;
}
.ovis-drag-drop_zone:hover.--disabled {
  cursor: default;
}
.ovis-drag-drop_zone.--disabled {
  background-color: lightgray;
  border: 2px dashed gray;
}
.ovis-drag-drop_zone.--active-drag {
  cursor: move;
  background-color: rgba(51, 49, 63, 0.6980392157);
  border: 2px dashed #0150b5;
}
.ovis-drag-drop_zone > p:nth-child(1) {
  font-weight: "Gilroy W05 Regular", sans-serif;
  max-width: 10rem;
}
.ovis-drag-drop_zone > p:nth-child(2) {
  line-height: 0rem;
  font-weight: "Gilroy W05 Medium", sans-serif;
}
.ovis-drag-drop_dropped-files {
  display: flex;
  flex-direction: column;
  max-height: 15rem;
  width: 100%;
}
.ovis-drag-drop_dropped-files_file {
  display: flex;
  width: 100%;
  margin: 0.5rem 0 0 0;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.5rem;
}
.ovis-drag-drop_dropped-files_file_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.ovis-drag-drop_dropped-files_file_left > p {
  margin-left: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ovis-drag-drop_dropped-files_file_left > span {
  width: 1.4rem;
}
.ovis-drag-drop_dropped-files_file_left > svg {
  width: 1.4rem;
}
.ovis-drag-drop_dropped-files_file > span {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ovis-drag-drop_dropped-files_file > span > svg {
  color: #d83a52;
  width: 1rem;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-preview-button {
  cursor: pointer;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ovis-preview-button.--disabled {
  cursor: default;
}
.ovis-preview-button.--disabled > svg > circle {
  stroke: lightgray;
}
.ovis-preview-button.--disabled > svg > path {
  stroke: lightgray;
}
.ovis-preview-button_modal .ovis-modal-scroll-container {
  width: 100%;
  max-width: 64vw;
}
.ovis-preview-button_modal .ovis-modal-scroll-container .ovis-modal-loading-container .ovis-modal-loading-text {
  color: white;
}
.ovis-preview-button_modal .ovis-modal-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ovis-preview-button_modal .ovis-modal {
  padding: 0;
  min-width: 0;
  width: 100%;
  max-width: 64vw;
  background: #003171;
}

.ovis-preview {
  width: 100%;
  height: 70vh;
}
.ovis-preview_placeholder {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.ovis-preview_streaming-video {
  border: none;
  width: 64vw;
  height: 35.5vw;
}
.ovis-preview .ovis-image-preview {
  height: 100%;
}
.ovis-preview .ovis-image-preview img {
  max-height: 100%;
}

.ovis-media-preview {
  width: 100%;
}

@media screen and (max-width: 766px) {
  .ovis-preview_streaming-video {
    width: 96vw;
    height: 53.5vw;
  }
  .ovis-preview-button_modal .ovis-modal {
    max-width: 96vw;
  }
  .ovis-preview-button_modal .ovis-modal-scroll-container {
    max-width: 96vw;
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

.ovis-editor {
  max-width: 600px;
  margin-top: 5px;
}
.ovis-editor_body {
  border-radius: 6px;
}
.ovis-editor_icon {
  border-radius: 6px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.1em;
}
.ovis-editor_icon svg {
  max-height: 15px;
  max-width: 15px;
  height: 100%;
  width: 100%;
}
.ovis-editor_icon svg path {
  stroke: #374152;
}
.ovis-editor_icon * {
  color: #374152;
}
.ovis-editor_icon:hover {
  background: rgba(0, 0, 0, 0.1);
}
.ovis-editor_icon.--active {
  background: rgba(0, 0, 0, 0.2);
}
.ovis-editor_font-dropdown > div > div {
  height: auto;
  background: transparent;
}
.ovis-editor_font-dropdown > div > div > div:nth-child(3) {
  background: #fff;
  width: 100px;
}
.ovis-editor_font-dropdown > div > div > div:nth-child(3) > div {
  background: white;
  font-size: 0.85rem;
}
.ovis-editor_font-dropdown > div > div > div:nth-child(3) > div > div {
  background: white;
  height: 25px;
  display: flex;
  align-items: center;
}
.ovis-editor_font-dropdown > div > div > div:nth-child(3) > div > div:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.ovis-editor_font-dropdown > div > div > div:nth-child(2) {
  background: transparent;
  border: none;
  height: 100%;
}
.ovis-editor_font-dropdown > div > div > div:nth-child(2) > div:nth-child(1) {
  background: transparent;
  padding-right: 0;
}
.ovis-editor_font-dropdown > div > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) {
  position: fixed;
}
.ovis-editor_font-dropdown > div > div > div:nth-child(2) > div:nth-child(2) > span {
  display: none;
}
.ovis-editor_font-dropdown > div > div > div:nth-child(2) > div:nth-child(2) > div {
  padding: 0.25rem;
}
.ovis-editor_font-dropdown > div > div > div:nth-child(2) > div:nth-child(2) > div svg {
  width: 10px;
}
.ovis-editor_toolbar {
  background: #d9d9d9;
  border-radius: 6px 6px 0 0px;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 32px;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0 0.375rem;
}
.ovis-editor_toolbar.sticky {
  border-top: 2px solid transparent;
  box-shadow: 0px 3px 5px -3px rgba(51, 51, 51, 0.5);
}
.ovis-editor_toolbar.disabled {
  color: rgba(51, 51, 51, 0.5);
  pointer-events: none;
}
.ovis-editor_toolbar .divider {
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  margin: 2px 5px;
  height: 100%;
}
.ovis-editor_popover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: #d9d9d9;
  border-radius: 6px;
  padding: 5px;
  display: flex;
}
.ovis-editor .ProseMirror {
  min-height: 300px;
  padding: 0.5rem;
  background: white;
  border-radius: 0px 0px 6px 6px;
  /*!
    Theme: GitHub
    Description: Light theme as seen on github.com
    Author: github.com
    Maintainer: @Hirse
    Updated: 2021-05-15
    Outdated base version: https://github.com/primer/github-syntax-light
    Current colors taken from GitHub's CSS
  */
}
.ovis-editor .ProseMirror * {
  color: #616771;
  margin: 0;
}
.ovis-editor .ProseMirror p {
  min-height: 1rem;
}
.ovis-editor .ProseMirror p br {
  display: none;
}
.ovis-editor .ProseMirror p strong {
  font-family: "Gilroy W05 Bold", sans-serif;
}
.ovis-editor .ProseMirror p a {
  color: #30a8d1;
  text-decoration: underline;
}
.ovis-editor .ProseMirror:focus-visible {
  outline: none;
}
.ovis-editor .ProseMirror ul,
.ovis-editor .ProseMirror ol {
  margin: 0.8em 0;
  padding: 0 0 0 2em;
}
.ovis-editor .ProseMirror ul li > ul,
.ovis-editor .ProseMirror ul li > ol,
.ovis-editor .ProseMirror ol li > ul,
.ovis-editor .ProseMirror ol li > ol {
  margin: 0;
}
.ovis-editor .ProseMirror ul li p,
.ovis-editor .ProseMirror ol li p {
  display: inline;
  margin: 0;
}
.ovis-editor .ProseMirror h1,
.ovis-editor .ProseMirror h2,
.ovis-editor .ProseMirror h3,
.ovis-editor .ProseMirror h4,
.ovis-editor .ProseMirror h5,
.ovis-editor .ProseMirror h6 {
  line-height: 1.1;
}
.ovis-editor .ProseMirror code {
  background: rgba(97, 97, 97, 0.1);
  color: #616161;
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 3px;
  padding: 1px;
}
.ovis-editor .ProseMirror pre {
  color: #24292e;
  background: #f5f7f9;
  font-family: "ui-monospace", "SFMono-Regular", "SF Mono", Menlo, Consolas, "Liberation Mono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ovis-editor .ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
  border: none;
}
.ovis-editor .ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}
.ovis-editor .ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 1.5rem 0;
}
.ovis-editor .ProseMirror span[data-mention] {
  background-color: rgba(0, 93, 113, 0.15);
  box-decoration-break: clone;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 4px;
  padding: 2px 4px;
  color: #005d71;
}
.ovis-editor .ProseMirror span[data-mention] > .char {
  display: inline-block;
  vertical-align: top;
  margin: 1px 2px 0 0;
  color: #0098b8;
}
.ovis-editor .ProseMirror span[data-emoji-suggestion] > img,
.ovis-editor .ProseMirror span[data-emoji-replacer] > img {
  width: 1.1em;
  height: 1.1em;
  margin: 0 0.05em;
  vertical-align: -0.25em;
}
.ovis-editor .ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: rgba(17, 17, 17, 0.3);
  pointer-events: none;
  height: 0;
  font-style: italic;
}
.ovis-editor .ProseMirror .color {
  white-space: nowrap;
}
.ovis-editor .ProseMirror .color::before {
  content: " ";
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  border: 1px solid rgba(128, 128, 128, 0.3);
  vertical-align: middle;
  margin-right: 0.2em;
  margin-bottom: 0.15em;
  border-radius: 50%;
  background: var(--color);
}
.ovis-editor .ProseMirror .hljs {
  color: #24292e;
  background: #ffffff;
}
.ovis-editor .ProseMirror .hljs-doctag,
.ovis-editor .ProseMirror .hljs-keyword,
.ovis-editor .ProseMirror .hljs-meta .hljs-keyword,
.ovis-editor .ProseMirror .hljs-template-tag,
.ovis-editor .ProseMirror .hljs-template-variable,
.ovis-editor .ProseMirror .hljs-type,
.ovis-editor .ProseMirror .hljs-variable.language_ {
  /* prettylights-syntax-keyword */
  color: #d73a49;
}
.ovis-editor .ProseMirror .hljs-title,
.ovis-editor .ProseMirror .hljs-title.class_,
.ovis-editor .ProseMirror .hljs-title.class_.inherited__,
.ovis-editor .ProseMirror .hljs-title.function_ {
  /* prettylights-syntax-entity */
  color: #6f42c1;
}
.ovis-editor .ProseMirror .hljs-attr,
.ovis-editor .ProseMirror .hljs-attribute,
.ovis-editor .ProseMirror .hljs-literal,
.ovis-editor .ProseMirror .hljs-meta,
.ovis-editor .ProseMirror .hljs-number,
.ovis-editor .ProseMirror .hljs-operator,
.ovis-editor .ProseMirror .hljs-variable,
.ovis-editor .ProseMirror .hljs-selector-attr,
.ovis-editor .ProseMirror .hljs-selector-class,
.ovis-editor .ProseMirror .hljs-selector-id {
  /* prettylights-syntax-constant */
  color: #005cc5;
}
.ovis-editor .ProseMirror .hljs-regexp,
.ovis-editor .ProseMirror .hljs-string,
.ovis-editor .ProseMirror .hljs-meta .hljs-string {
  /* prettylights-syntax-string */
  color: #032f62;
}
.ovis-editor .ProseMirror .hljs-built_in,
.ovis-editor .ProseMirror .hljs-symbol {
  /* prettylights-syntax-variable */
  color: #e36209;
}
.ovis-editor .ProseMirror .hljs-comment,
.ovis-editor .ProseMirror .hljs-code,
.ovis-editor .ProseMirror .hljs-formula {
  /* prettylights-syntax-comment */
  color: #6a737d;
}
.ovis-editor .ProseMirror .hljs-name,
.ovis-editor .ProseMirror .hljs-quote,
.ovis-editor .ProseMirror .hljs-selector-tag,
.ovis-editor .ProseMirror .hljs-selector-pseudo {
  /* prettylights-syntax-entity-tag */
  color: #22863a;
}
.ovis-editor .ProseMirror .hljs-subst {
  /* prettylights-syntax-storage-modifier-import */
  color: #24292e;
}
.ovis-editor .ProseMirror .hljs-section {
  /* prettylights-syntax-markup-heading */
  color: #005cc5;
  font-weight: bold;
}
.ovis-editor .ProseMirror .hljs-bullet {
  /* prettylights-syntax-markup-list */
  color: #735c0f;
}
.ovis-editor .ProseMirror .hljs-emphasis {
  /* prettylights-syntax-markup-italic */
  color: #24292e;
  font-style: italic;
}
.ovis-editor .ProseMirror .hljs-strong {
  /* prettylights-syntax-markup-bold */
  color: #24292e;
  font-weight: bold;
}
.ovis-editor .ProseMirror .hljs-addition {
  /* prettylights-syntax-markup-inserted */
  color: #22863a;
  background-color: #f0fff4;
}
.ovis-editor .ProseMirror .hljs-deletion {
  /* prettylights-syntax-markup-deleted */
  color: #b31d28;
  background-color: #ffeef0;
}
.ovis-editor_preview {
  word-wrap: break-word;
  white-space: break-spaces;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 6px;
  margin-top: 1rem;
  padding: 0.5rem;
}
.ovis-editor_preview p {
  min-height: 1rem;
}
.ovis-editor_preview p strong {
  font-family: "Gilroy W05 Medium", sans-serif;
}
.ovis-editor_preview p a {
  color: #30a8d1;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-panel {
  box-shadow: 2px 2px 5px rgb(27, 27, 27);
  width: 100%;
  background: rgba(241, 243, 245, 0.9529411765) no-repeat padding-box;
  justify-content: space-around;
  padding: 0 1rem;
  margin: 0 0 1rem;
  color: #021d42;
}
.ovis-panel .ovis-panel-item {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  flex-wrap: nowrap;
  max-height: 100%;
}
.ovis-panel .ovis-panel-item > * {
  margin-right: 1.5rem;
}
.ovis-panel .ovis-panel-item > *:nth-last-child(1) {
  margin-right: 0;
}
.ovis-panel .ovis-panel-item.column {
  flex-direction: column;
  align-items: flex-start;
}
.ovis-panel.light .ovis-panel-title {
  color: #021d42;
}
.ovis-panel .ovis-panel-item.dividers:not(:last-child) {
  border-bottom: 1px solid #6f7b8a;
}

.ovis-panel-title * {
  margin-right: 1rem;
}

.ovis-panel.column {
  flex-direction: column;
  justify-content: flex-start;
}

.ovis-panel.dark {
  background: rgba(15, 33, 57, 0.8980392157) no-repeat padding-box;
  color: white;
}

.panel-admin-header-buttons {
  justify-content: flex-start;
}

.panel-admin-header-inputs {
  justify-content: space-between;
}

.panel-admin-header-buttons,
.panel-admin-header-inputs {
  width: 100%;
  display: flex;
}
.panel-admin-header-buttons *,
.panel-admin-header-inputs * {
  margin-right: 1rem;
}
.panel-admin-header-buttons *:last-child,
.panel-admin-header-inputs *:last-child {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .ovis-panel {
    padding: 0 1rem;
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-animation-wrapper.--hidden {
  z-index: -1;
  max-height: 0;
  visibility: hidden;
}
.ovis-animation-wrapper.--visible {
  z-index: 1;
  max-height: 100%;
  visibility: visible;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-15px);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(-15px);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-15px);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(15px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(15px);
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-dropdown-toggle {
  z-index: 10001;
  width: fit-content;
  height: fit-content;
}

.ovis-dropdown-toggle-arrow {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  transform: translate(-50%) rotate(45deg);
  content: "";
  display: block;
  background: white;
  top: 0;
}
.ovis-dropdown-toggle-arrow.--down {
  transform: translate(-50%, 0);
  top: calc(100% + 16px);
}
.ovis-dropdown-toggle-arrow.--down .ovis-dropdown-body::before {
  bottom: initial;
  top: 0;
}
.ovis-dropdown-toggle-arrow.--up {
  top: 0;
  transform: translate(-50%, calc(-100% - 15px));
}
.ovis-dropdown-toggle-arrow.--up .ovis-dropdown-body::before {
  top: initial;
  bottom: 0;
}
.ovis-dropdown-toggle-arrow.--left {
  transform: translate(calc(-100% - 15px), -50%);
  top: 50%;
  left: 0%;
}
.ovis-dropdown-toggle-arrow.--left .ovis-dropdown-body::before {
  top: 50%;
  right: 0;
  left: initial;
  transform: translate(0, -50%) rotate(45deg);
}
.ovis-dropdown-toggle-arrow.--right {
  transform: translate(calc(100% + 15px), -50%);
  top: 50%;
  right: 0%;
}
.ovis-dropdown-toggle-arrow.--right .ovis-dropdown-body::before {
  top: 50%;
  right: initial;
  left: 0;
  transform: translate(0, -50%) rotate(45deg);
}

.ovis-dropdown-container {
  height: 100%;
  width: 100%;
  z-index: 10000;
}

.ovis-dropdown {
  pointer-events: initial !important;
  position: absolute;
  transform: translate(-50%, 0%);
  left: 50%;
  top: calc(100% + 0.5rem);
  transition: box-shadow 0.25s;
  transition-delay: box-shadow 0.25;
  width: 100%;
}
.ovis-dropdown.--hidden {
  padding: 0;
  margin: 0;
  visibility: hidden;
  overflow: hidden;
  box-shadow: none;
}
.ovis-dropdown.--noArrow .ovis-dropdown-body::before {
  display: none;
}
.ovis-dropdown.--down {
  transform: translate(-50%, 0);
  top: calc(100% + 0.375rem);
}
.ovis-dropdown.--down .ovis-dropdown-body::before {
  bottom: initial;
  top: 0;
}
.ovis-dropdown.--up {
  top: 0;
  transform: translate(-50%, calc(-100% - 15px));
}
.ovis-dropdown.--up .ovis-dropdown-body::before {
  top: initial;
  bottom: 0;
}
.ovis-dropdown.--left {
  transform: translate(calc(-100% - 15px), -50%);
  top: 50%;
  left: 0%;
}
.ovis-dropdown.--left .ovis-dropdown-body::before {
  top: 50%;
  right: 0;
  left: initial;
  transform: translate(0, -50%) rotate(45deg);
}
.ovis-dropdown.--right {
  transform: translate(calc(100% + 15px), -50%);
  top: 50%;
  right: 0%;
}
.ovis-dropdown.--right .ovis-dropdown-body::before {
  top: 50%;
  right: initial;
  left: 0;
  transform: translate(0, -50%) rotate(45deg);
}

.ovis-dropdown-body {
  background: white;
  width: fit-content;
  background: white;
  overflow: hidden;
  border-radius: 0.2rem;
  padding: 0.75rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.ovis-dropdown-body * {
  pointer-events: initial !important;
}
.ovis-dropdown-body p:nth-child(1) {
  margin-top: 0;
}
.ovis-dropdown-body p {
  margin: 0.5rem 0;
  font-size: 0.85rem;
  line-height: 1.4em;
  color: #616771;
}
.ovis-dropdown-body::before {
  position: fixed;
  left: 50%;
  width: 30px;
  height: 30px;
  transform: translate(-50%) rotate(45deg);
  content: "";
  display: block;
  background: white;
  top: 0;
  z-index: -1;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 766px) {
  .ovis-dropdown {
    max-height: 60vh;
    max-width: 95vw;
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
/* Custom properties */
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.ovis-tooltip-wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.ovis-tooltip-container {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
}
.ovis-tooltip-container.top {
  top: calc(var(--tooltip-margin) * -1.1);
}
.ovis-tooltip-container.top .ovis-tooltip-tip::before {
  top: 100%;
  border-top-color: #d9d9d9;
}
.ovis-tooltip-container.right {
  left: calc(100% + var(--tooltip-margin) * 0.3);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.ovis-tooltip-container.right .ovis-tooltip-tip::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: #d9d9d9;
}
.ovis-tooltip-container.bottom {
  bottom: calc(var(--tooltip-margin) * -1.1);
}
.ovis-tooltip-container.bottom .ovis-tooltip-tip::before {
  bottom: 100%;
  border-bottom-color: #d9d9d9;
}
.ovis-tooltip-container.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin) * 0.3);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.ovis-tooltip-container.left .ovis-tooltip-tip::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: #d9d9d9;
}

.ovis-tooltip-tip {
  border-radius: 2px;
  padding: 6px;
  color: #616771;
  background: #d9d9d9;
  font-size: 14px;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  user-select: none;
  text-transform: none;
  transition: all 0.5s ease;
}

/* CSS border triangles */
.ovis-tooltip-tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-table-wrapper {
  display: flex;
  flex-direction: column;
  white-space: pre;
  width: 100%;
  margin-bottom: 1rem;
  overflow-y: visible;
  overflow-x: scroll;
}
.ovis-table-wrapper .ovis-table {
  overflow-x: scroll;
  width: 100%;
  border-spacing: 0px;
}
.ovis-table-wrapper .ovis-table .ovis-table-thead .ovis-table-cell {
  background-color: #5abada;
  color: white;
  font-size: 0.875rem;
  font-weight: "Gilroy W05 Medium", sans-serif;
  line-height: 1.6em;
  transition: background-color 0.18s;
  user-select: none;
  padding: 0.5rem;
}
.ovis-table-wrapper .ovis-table .ovis-table-thead .ovis-table-cell.--has-sort:hover {
  background-color: #2a98bd;
}
.ovis-table-wrapper .ovis-table .ovis-table-thead .ovis-table-cell-fix-left {
  background-color: unset;
}
.ovis-table-wrapper .ovis-table .ovis-table-tbody .ovis-table-cell {
  font-size: 0.875rem;
  font-weight: "Gilroy W05 Light", sans-serif;
}
.ovis-table-wrapper .ovis-table .ovis-table-cell {
  border-spacing: 0px;
  padding: 0.5rem;
  user-select: all;
  color: black;
}
.ovis-table-wrapper .ovis-table .ovis-table-cell .ovis-table-cell-content {
  display: flex;
  align-items: center;
  min-width: 2.5rem;
}
.ovis-table-wrapper .ovis-table .ovis-table-cell.--action-col svg {
  width: 17px;
  height: auto;
  cursor: pointer;
}
.ovis-table-wrapper .ovis-table .ovis-table-drag-icon-container {
  cursor: move;
}
.ovis-table-wrapper .ovis-table .ovis-table-drag-icon-container svg {
  cursor: move !important;
}
.ovis-table-wrapper .ovis-table .ovis-table-icon-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 15px;
  width: 100%;
}
.ovis-table-wrapper .ovis-table .ovis-table-action-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.3rem;
}
.ovis-table-wrapper .ovis-table tr:nth-child(even) {
  background-color: #f5f5f5;
}
.ovis-table-wrapper .ovis-table tr:nth-child(even) .ovis-table-cell-fix-left {
  background-color: inherit;
}
.ovis-table-wrapper .ovis-table tr:nth-child(odd) {
  background-color: #fff;
}
.ovis-table-wrapper .ovis-table .auth-required-link {
  color: #0150b5;
}
.ovis-table-wrapper .ovis-table-title {
  color: rgb(2, 29, 66);
  font-size: 1.44rem;
  font-weight: 100;
  margin-bottom: 10px;
}
.ovis-table-wrapper .ovis-table-column-sorter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 1rem;
  height: 1.8rem;
  margin-left: 0.5rem;
  cursor: pointer;
}
.ovis-table-wrapper .ovis-table-column-sorter-container svg {
  fill: white;
  width: 15px;
}
.ovis-table-wrapper .ovis-table-column-sorter-container svg:nth-child(1) {
  bottom: -2px;
}
.ovis-table-wrapper .ovis-table-column-sorter-container svg:nth-child(2) {
  top: -2px;
}
.ovis-table-wrapper.--loading {
  height: 20rem;
  border: 1px solid #f2f2f2;
  opacity: 0.5;
  overflow-y: hidden;
}
.ovis-table-wrapper .ovis-table-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14rem;
  color: black;
  width: 100%;
  background-color: #f2f2f2;
}

.ovis-table-and-pagination-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ovis-table-pagination {
  align-self: flex-end;
  margin: 0 0 1rem 1rem;
}

.ovis-table-loader-container {
  position: absolute;
  height: 20rem;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
}
.actions button {
  display: flex;
  align-items: center;
}

.ovis-table-tbody > tr > td {
  white-space: break-spaces;
}

.ovis-form-table_buttons {
  display: flex;
  padding: 1rem 0;
}
.ovis-form-table_buttons .ovis-btn {
  margin-right: 0.5rem;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-shadow {
  background: rgba(2, 29, 66, 0.5019607843);
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 99;
}
.ovis-shadow.--transparent {
  background-color: transparent;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-modal {
  display: flex;
  flex-direction: column;
  background: white;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 5000vh;
  width: 100%;
  min-width: 50rem;
  max-width: 65rem;
  padding: 2.5rem 1.5rem 1.5rem;
  overflow: visible;
  top: 0;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: max-height 2s, top 0.4s ease-in-out;
  border-radius: 16px;
}
.ovis-modal.--small-padding {
  padding: 1rem;
}
.ovis-modal.--small {
  max-width: 420px;
  min-width: 420px;
  width: 420px;
}
.ovis-modal.--small .ovis-modal_scroll-container {
  min-width: initial;
}
.ovis-modal.--large {
  max-width: 75rem;
  min-width: 70rem;
}
.ovis-modal.--large .ovis-modal_scroll-container {
  min-width: initial;
}
.ovis-modal_container {
  position: fixed;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  max-width: 100vw;
  width: 100vw;
  top: 0%;
  left: 0;
  z-index: 99999;
}
.ovis-modal_container.--small .ovis-modal_scroll-container {
  min-width: initial;
}
.ovis-modal_scroll-container {
  height: 100vh;
  min-width: 50rem;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 999;
}
.ovis-modal_scroll-container::-webkit-scrollbar-thumb {
  background-color: rgb(48, 127, 195);
  border-radius: 8px;
}
.ovis-modal_scroll-container::-webkit-scrollbar-track {
  margin: 2rem 0;
}
.ovis-modal_loader {
  align-items: center;
  background: rgba(123, 128, 134, 0.2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.ovis-modal_confirm-text {
  margin: 2rem 0;
  text-align: center;
}
.ovis-modal_content-container {
  height: fit-content;
  overflow: visible;
  width: 100%;
}
.ovis-modal_title-wrapper {
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
}
.ovis-modal_title {
  width: 100%;
  display: block;
}
.ovis-modal_close-btn {
  height: 1.75rem;
  width: 1.75rem;
  top: 1rem;
  right: 1rem;
  position: absolute;
  cursor: pointer;
  z-index: 15;
}
.ovis-modal_close-btn svg rect {
  fill: #616771;
  transition: 0.1s fill linear;
}
.ovis-modal_close-btn:hover svg rect {
  fill: #0150b5;
}
.ovis-modal.loading {
  top: 7rem;
  height: 25rem;
  max-height: 25rem;
  overflow: hidden;
}
.ovis-modal.small {
  top: 10%;
  width: 50%;
}
.ovis-modal.small .ovis-modal-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 8rem;
}
.ovis-modal.small button[type=reset] {
  align-self: center;
}
.ovis-modal.unsupportedBrowserAlert {
  max-height: 22rem;
  width: 50%;
}
.ovis-modal.unsupportedBrowserAlert .ovis-modal-content-container .modal-browser-alert {
  top: calc(4rem + 1%);
}

.ovis-modal_loading-container {
  position: absolute;
  left: 50%;
  top: 40%;
  margin-top: -8rem;
  margin-left: -20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40rem;
  height: 16rem;
  flex-grow: 1;
  line-height: 2.5rem;
}
.ovis-modal_loading-container .ovis-modal-loading-text {
  margin-bottom: 2rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media screen and (max-width: 767px) {
  .ovis-modal_scroll-container {
    min-width: unset;
    width: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .ovis-modal_scroll-container::-webkit-scrollbar-thumb {
    opacity: 0;
    width: 0;
  }
  .ovis-modal {
    width: 100%;
    min-width: initial;
    padding: 1.7rem 1rem 3.5rem;
    transform: translate(0, 0);
  }
  .ovis-modal.unsupportedBrowserAlert {
    max-height: 45%;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .ovis-modal_scroll-container {
    width: calc(100% + 2rem);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .ovis-modal_scroll-container::-webkit-scrollbar-thumb {
    opacity: 0;
    width: 0;
  }
  .ovis-modal_container {
    max-width: initial;
    width: 101.7%;
  }
  .ovis-modal {
    width: 100%;
    min-width: initial !important;
    max-width: initial !important;
    padding: 1.7rem 1rem 3.5rem;
    transform: translate(0, 0);
  }
  .ovis-modal.unsupportedBrowserAlert {
    max-height: 45%;
    width: 100%;
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-reporting-table * {
  font-size: 0.85rem;
}
.ovis-reporting-table_body {
  width: 100%;
}
.ovis-reporting-table.--is-single-col table,
.ovis-reporting-table.--is-single-col table * {
  width: 100%;
  display: block;
}
.ovis-reporting-table.--is-single-col td:nth-child(1) {
  border-right: none;
}
.ovis-reporting-table.--no-left-sticky .ovis-reporting-table_body thead td:nth-child(1) {
  left: initial;
}
.ovis-reporting-table.--no-left-sticky .ovis-reporting-table_body tbody td:nth-child(1) {
  left: initial;
}
.ovis-reporting-table .reporting-date {
  text-align: left;
  width: 100%;
  min-height: 22px;
}
.ovis-reporting-table .reporting-date p {
  color: #616771 !important;
}
.ovis-reporting-table .wrapper {
  max-height: 600px;
  width: 100%;
  overflow-x: scroll;
}
.ovis-reporting-table.isChrome tbody tr.selected-row td,
.ovis-reporting-table.isChrome tbody tr.selected-row td:nth-child(1) {
  top: 37px;
}
.ovis-reporting-table_body {
  position: relative;
}
.ovis-reporting-table_body thead td {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0px;
  background: #9a9ba8;
  z-index: 51;
  color: white;
  padding: 0.5rem 1rem;
}
.ovis-reporting-table_body thead td:nth-child(1) {
  left: 0;
  z-index: 52;
  box-shadow: inset -7px 0 1px -7px white;
}
.ovis-reporting-table_body tbody tr:nth-child(odd) td:nth-child(1) {
  background: white;
}
.ovis-reporting-table_body tbody tr {
  cursor: pointer;
}
.ovis-reporting-table_body tbody tr.selected-row td,
.ovis-reporting-table_body tbody tr.selected-row td:nth-child(1) {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0px;
  z-index: 55;
  background-color: #307fc3;
  color: white;
}
.ovis-reporting-table_body tbody tr.selected-row td:nth-child(1) {
  z-index: 70;
}
.ovis-reporting-table_body tbody td.modal-cell a {
  color: #003171;
}
.ovis-reporting-table_body tbody td.modal-cell a:hover {
  color: #0150b5;
}
.ovis-reporting-table_body tbody td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 50;
  position: -webkit-sticky; /* for Safari */
  box-shadow: inset -7px 0 1px -7px black;
}
.ovis-reporting-table_body tfoot td {
  background: #9c9eac;
  color: white;
  bottom: 0;
  position: sticky;
  position: -webkit-sticky;
  padding: 0.35rem 1rem;
}
.ovis-reporting-table_body tfoot td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 50;
  position: -webkit-sticky; /* for Safari */
  box-shadow: inset -7px 0 1px -7px white;
}
.ovis-reporting-table_title {
  width: 100%;
  z-index: 52;
  padding-right: 3rem;
}
.ovis-reporting-table_title h3 {
  font-size: 1.3rem;
  color: #616771;
  font-weight: 100;
  padding: 0 0.5rem 1rem 0 !important;
}
.ovis-reporting-table_title p {
  color: white;
  margin-left: 1rem;
}
.ovis-reporting-table_header td {
  color: white;
}
.ovis-reporting-table_row td {
  background: white;
}
.ovis-reporting-table_row:nth-child(even) td {
  background: #f1f3f5;
}
.ovis-reporting-table td {
  padding: 0.25rem 1rem;
  white-space: nowrap;
  border-spacing: 0;
  border-collapse: collapse;
  color: black;
}
.ovis-reporting-table.full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 15000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease forwards;
}
.ovis-reporting-table.full-screen .wrapper {
  max-height: calc(100vh - 125px);
}
.ovis-reporting-table.full-screen .ovis-reporting-table_container {
  max-width: 100%;
}
.ovis-reporting-table.full-screen .ovis-reporting-table_shadow {
  opacity: 1;
}
.ovis-reporting-table.full-screen .ovis-reporting-table_container {
  animation: moveUp 0.5s ease forwards;
}
.ovis-reporting-table .resize-button {
  top: 50%;
  transform: translateY(-50%);
}
.ovis-reporting-table_shadow {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.85);
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes moveUp {
  0% {
    transform: translate(0, 50px) scale(0.95);
  }
  100% {
    transform: translate(0, 0px) scale(1);
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-chart-container .resize-button {
  top: 5px;
  right: 5px;
}
.ovis-chart-container .ovis-chart .reporting-date {
  height: 30px;
}
.ovis-chart-container .ovis-chart .reporting-date p {
  font-size: 14px;
  margin-left: 2rem;
}
.ovis-chart-container .ovis-chart .highcharts-credits {
  display: none;
}
.ovis-chart-container .ovis-reporting_shadow {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.85);
  opacity: 0;
}
.ovis-chart-container.full-screen .ovis-chart-fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 15000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease forwards;
}
.ovis-chart-container.full-screen .ovis-chart-fullscreen .ovis-chart {
  animation: moveUp 0.5s ease forwards;
}
.ovis-chart-container.full-screen .ovis-chart-fullscreen .reporting-date p {
  color: white;
}
.ovis-chart-container.full-screen .ovis-chart-fullscreen .ovis-reporting_shadow {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes moveUp {
  0% {
    transform: translate(0, 50px) scale(0.95);
  }
  100% {
    transform: translate(0, 0px) scale(1);
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.reporting-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  -webkit-gap: 20px;
}
.reporting-layout .report-row {
  gap: 1rem;
  -webkit-gap: 1rem;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
.reporting-layout .report {
  min-width: 300px;
}
.reporting-layout .report:nth-last-child(1) {
  margin-right: 0 !important;
}

.reporting-summary .report-date {
  margin-bottom: 1rem;
}
.reporting-summary p {
  line-height: 1.3;
  color: #33313f;
  font-weight: 500;
}
.reporting-summary p span {
  color: #616771;
  margin-left: 5px;
  font-weight: 400;
}

.resize-button {
  width: 29px;
  height: 29px;
  padding: 2px;
  border-radius: 5px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  z-index: 500;
  transition: background-color 250ms ease;
}
.resize-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.resize-button svg {
  width: 100%;
  height: 100%;
}
.resize-button svg path:last-child {
  transition: fill 250ms ease;
  fill: #003171;
}
.resize-button:hover svg path:last-child {
  fill: #2a98bd;
}

.reporting-widget .ovis-panel.--is-loading {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ovis-reporting-loader {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ovis-reporting-loader p {
  font-size: 1.25rem;
}
.ovis-reporting-loader .chart-loader {
  display: flex;
  margin-bottom: 1rem;
}
.ovis-reporting-loader .chart {
  width: 50px;
  height: 40px;
  position: relative;
  border-bottom: 1px solid white;
}
.ovis-reporting-loader .chart .bar {
  position: absolute;
  background: #0150b5;
  bottom: 1px;
  width: 7.5px;
  height: 100%;
  border-radius: 1px 1px 0px 0;
}
.ovis-reporting-loader .chart .bar-one {
  left: 14.2857142857%;
  background: #13bf1f;
  animation: loading-one 2s ease infinite;
}
.ovis-reporting-loader .chart .bar-three {
  left: 66.6666666667%;
  animation: loading-two 3s ease infinite;
}
.ovis-reporting-loader .chart .bar-two {
  left: 40%;
  background: #d83a52;
  animation: loading-three 3.5s ease infinite;
}
.ovis-reporting-loader .line-chart {
  height: 50px;
  width: 50px;
  border-bottom: 2px solid #0150b5;
  border-left: 2px solid #0150b5;
  position: relative;
}
.ovis-reporting-loader .leg {
  position: absolute;
  height: 20px;
  width: 20px;
}
.ovis-reporting-loader .leg div {
  background-color: #0150b5;
  height: 2px;
}
.ovis-reporting-loader .leg-one {
  bottom: 11px;
  left: -3px;
  transform: rotate(-45deg);
}
.ovis-reporting-loader .leg-one div {
  width: 20px;
  animation: grow 1s linear infinite;
  position: absolute;
  bottom: 0;
}
.ovis-reporting-loader .leg-two {
  width: 10px;
  height: 10px;
  transform: rotate(35deg);
  bottom: 10px;
  left: 17px;
}
.ovis-reporting-loader .leg-two div {
  position: absolute;
  width: 10px;
  animation: grow-two 1s linear infinite;
}
.ovis-reporting-loader .leg-three {
  transform: rotate(-45deg);
  width: 25px;
  height: 25px;
  left: 16px;
  bottom: 21px;
}
.ovis-reporting-loader .leg-three div {
  width: 25px;
  position: absolute;
  bottom: 0;
  animation: grow-three 1s linear infinite;
}

@keyframes loading-one {
  0% {
    max-height: 30px;
  }
  50% {
    max-height: 5px;
  }
  100% {
    max-height: 30px;
  }
}
@keyframes loading-two {
  0% {
    max-height: 40px;
  }
  50% {
    max-height: 15px;
  }
  100% {
    max-height: 40px;
  }
}
@keyframes loading-three {
  0% {
    max-height: 5px;
  }
  50% {
    max-height: 25px;
  }
  100% {
    max-height: 5px;
  }
}
@keyframes grow {
  0% {
    max-width: 0px;
  }
  33% {
    max-width: 20px;
  }
  100% {
    max-width: 20px;
  }
}
@keyframes grow-two {
  0% {
    max-width: 0px;
  }
  33% {
    max-width: 0px;
  }
  66% {
    max-width: 10px;
  }
  100% {
    max-width: 10px;
  }
}
@keyframes grow-three {
  0% {
    max-width: 0px;
  }
  33% {
    max-width: 0px;
  }
  66% {
    max-width: 0px;
  }
  100% {
    max-width: 25px;
  }
}
.image {
  opacity: 0;
  transition: opacity 0.3s;
}

.image-loaded {
  opacity: 1;
}

.image.--second {
  position: absolute;
  height: 100%;
  width: 100%;
}
.--skeleton {
  overflow: hidden;
  opacity: 0.7;
}

.skeleton-gradient {
  position: absolute;
}

.skeleton-gradient.--slide-down {
  left: -25%;
  height: 200%;
  width: 150%;
  top: -250%;
  transform: rotate(-16deg);
  animation: slideDown 3s cubic-bezier(0.4, 0, 0.2, 1) 0.4s infinite;
}

.skeleton-gradient.--slide-across {
  left: -200%;
  height: 100%;
  width: 200%;
  top: 0%;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.17), rgba(0, 0, 0, 0));
  animation: slideAcross 3s cubic-bezier(0.4, 0, 0.2, 1) 0.4s infinite;
}
@charset "UTF-8";
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.white-form {
  background: white;
  color: black;
}

.ovis-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
.ovis-form_section {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
.ovis-form-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 0 1 auto;
  align-items: flex-start;
  width: 100%;
  grid-column-start: 1;
  white-space: pre-wrap;
  grid-column-end: 3;
}
.ovis-form-row.--half-row {
  grid-column-start: initial;
  grid-column-end: initial;
}
.ovis-form-input-container {
  width: 100%;
}
.ovis-form .ovis-text-area {
  margin-top: 5px;
}
.ovis-form input[type=date] {
  position: relative;
}
.ovis-form input[type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: -6px;
  background-color: white;
}
.ovis-form input[type=date]::-webkit-clear-button {
  right: 1.54em;
}
.ovis-form input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ovis-form input[type=date]::-webkit-calendar-picker-indicator {
  right: 0;
  padding: 0.539em 0.34em;
}
.ovis-form-label {
  line-height: 1;
}
.ovis-form-label sup {
  position: absolute;
}
.ovis-form-label p,
.ovis-form-label sup {
  font-size: 0.875rem;
  font-weight: "Gilroy W05 Regular", sans-serif;
  color: rgba(51, 49, 63, 0.6980392157);
}
.ovis-form-label i {
  font-size: 0.64rem;
  font-weight: "Gilroy W05 Light", sans-serif;
  color: rgba(51, 49, 63, 0.6980392157);
}
.ovis-form-image-upload label {
  padding-bottom: 0.5rem;
}

form.ovis-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: "Gilroy W05 Regular", sans-serif;
}
form.ovis-form br {
  display: block;
}
form.ovis-form .plain-text {
  font-weight: 300;
}
form.ovis-form .ovis-form-row.submit {
  justify-content: flex-end;
  margin-top: 0.5rem;
  margin-bottom: 0;
}
form.ovis-form .ovis-form-row .ovis-form-col .asset-preview-link {
  color: #0150b5;
  text-align: left;
}
form.ovis-form .ovis-form-row .ovis-form-col p {
  margin-bottom: 0;
}
form.ovis-form .ovis-form-row .ovis-form-col .file-input-icon {
  z-index: 1;
  width: 1rem;
  margin: 0 0.5rem 0.5rem;
}
form.ovis-form .ovis-form-row .ovis-form-col .link-input-icon {
  position: absolute;
  z-index: 1;
  padding-left: 0.6rem;
}
form.ovis-form .ovis-form-row .ovis-form-col .preview-image,
form.ovis-form .ovis-form-row .ovis-form-col .preview-image-large {
  margin: 0 1rem 0 0;
}
form.ovis-form .ovis-form-row .ovis-form-col .preview-image {
  width: 35px;
  font-size: small;
}
form.ovis-form .ovis-form-row .ovis-form-col .preview-image-large {
  width: 100%;
}
form.ovis-form .ovis-form-row .ovis-form-col .ovis-btn-primary,
form.ovis-form .ovis-form-row .ovis-form-col .ovis-btn-secondary,
form.ovis-form .ovis-form-row .ovis-form-col .ovis-form-btn {
  margin-top: 1rem;
}
form.ovis-form .ovis-form-row .ovis-form-input-container {
  margin-right: 1.1rem;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 767px) {
  form.ovis-form .ovis-form-row .ovis-form-input-container {
    width: 100%;
  }
}
form.ovis-form .ovis-form-row .ovis-form-input-container.--long-label {
  padding: 0rem 0rem 1rem 1.8rem;
}
form.ovis-form .ovis-form-row .remove-file-btn {
  border: none;
  cursor: pointer;
  width: 35px;
  height: 35px;
  font-size: small;
  padding: 0 0.3rem;
  text-align: center;
  text-justify: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 0;
}
form.ovis-form .ovis-form-row .remove-file-btn:hover svg {
  height: 35px;
  width: 35px;
}
form.ovis-form .ovis-form-row .remove-file-btn:hover svg path {
  fill: #e06577;
}
form.ovis-form .ovis-form-row .remove-file-btn svg {
  height: 35px;
  width: 35px;
}
form.ovis-form .ovis-form-row .remove-file-btn svg path {
  fill: #d83a52;
}
form.ovis-form .ovis-form-row.ovis-multi-upload .ovis-form-col.ovis-form-input-container {
  width: 70%;
}
form.ovis-form .ovis-html {
  flex-direction: column;
  margin-bottom: 2rem;
}
form.ovis-form .ovis-html label {
  margin-bottom: 1.2rem;
}
form.ovis-form .ovis-html .ovis-form-input-container {
  width: 97%;
}
form.ovis-form .grey-box {
  height: 10rem;
  background-color: rgba(192, 192, 192, 0.178);
  width: 100%;
  border: 1px solid #aeb3bc;
}
form.ovis-form .ovis-upload .ovis-form-input-container {
  display: flex;
  flex-direction: column;
  margin-top: 0.6rem;
  align-items: flex-start;
}
form.ovis-form .ovis-upload .ovis-form-input-container .file-name-preview {
  margin-bottom: 0.5rem;
}
form.ovis-form .ovis-static-list .static-list {
  width: 100%;
}
form.ovis-form .ovis-static-list .static-list p {
  margin: 0 0 0.75rem;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2em;
}
form.ovis-form .ovis-checkbox {
  max-width: 100%;
}
form.ovis-form .ovis-checkbox .ovis-form-input-container {
  width: 100%;
}
form.ovis-form .ovis-form-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 3.5em;
  color: #0150b5;
  background: white no-repeat padding-box;
  border: 0.5px solid #0150b5;
  transition: background-color 0.18s;
  font-weight: 500;
  font-size: 0.8em;
  letter-spacing: 3px;
}
form.ovis-form .ovis-form-button:hover {
  background-color: #0150b5;
  color: white;
}
form.ovis-form .ovis-form-button:active {
  background-color: rgba(24, 24, 24, 0.2509803922);
}
form.ovis-form .ovis-form-col.ovis-form-input-container {
  min-height: 3rem;
}
form.ovis-form .ovis-mau {
  padding-top: 1rem;
}
form.ovis-form .ovis-mau_title {
  margin-bottom: 1rem !important;
}
form.ovis-form .form-text-container {
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  align-self: center;
  display: flex;
  justify-content: center;
}
form.ovis-form .form-text-container i.form-text,
form.ovis-form .form-text-container p.form-text {
  display: flex;
  justify-content: center;
}
form.ovis-form .form-text-container i.form-text {
  font-size: 0.7rem;
}
form.ovis-form .ovis-static .ovis-form-input-container p {
  text-align: left;
}
form.ovis-form .ovis-btn[type=submit] {
  align-self: flex-start;
  margin-right: 1rem;
}
form.ovis-form .ovis-input:invalid + .field-valid-indicator::before {
  content: "✖";
  color: red;
}
form.ovis-form .ovis-input.--invalid + .field-valid-indicator::before {
  content: "✖";
  color: red;
}
form.ovis-form .ovis-input.--valid + .field-valid-indicator::before {
  content: "✓";
  color: green;
}

.ovis-form-sub-title, form.ovis-form .ovis-checkbox > label {
  font-size: 1.563rem;
  font-weight: 100;
  margin-bottom: 0;
  border-bottom: 1px solid #d9d9d9;
}

.ovis-form-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-self: flex-end;
}
.ovis-form-btn-container button[type=submit] {
  margin-top: 2em;
}

.remove-field-value-btn {
  background: transparent;
  transform: translate(-270%, -80%);
  height: 0.85rem;
  width: 0.85rem;
  top: 50%;
  right: 0;
  position: absolute;
}
.remove-field-value-btn svg {
  height: 0.85rem;
  width: 0.85rem;
}
.remove-field-value-btn path {
  fill: #d83a52;
}
.remove-field-value-btn:hover path {
  fill: #e57a8a;
}
.remove-field-value-btn.date {
  right: 47%;
}

.plain-text {
  display: flex;
  text-align: left;
  font-weight: 400;
  align-self: flex-start;
  padding-top: 0.5rem;
}

.ovis-alert-text {
  position: absolute;
  top: -18px;
  right: 0;
  width: auto;
  line-height: 1rem;
  font-size: 0.7rem;
  color: white;
  background: #d83a52;
  margin-bottom: 1rem;
  padding: 0 5px;
  display: flex;
  justify-content: center;
}

.form-errors {
  color: #d83a52;
}
.form-errors .form-error {
  color: #d83a52;
}

.upload-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-label {
  background: white;
  color: #616771;
  text-align: center;
  transition: background-color 0.18s, color 0.18s;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 140%;
  border: 1px solid rgba(156, 156, 156, 0.561);
  border-radius: 0.3rem;
  cursor: pointer;
  width: 6rem;
  font-size: 0.8rem;
  margin: 0 0.5rem 0 0;
  padding: 0 1.2rem;
  height: 3rem;
}
.file-label img {
  margin-right: 0.5rem;
}
.file-label:hover {
  background-color: #0166e8;
  border-color: #0166e8;
  color: white;
}
.file-label:active {
  background-color: #1e80fe;
  border-color: #0166e8;
}

.ovis-file-label-plus {
  display: flex;
  border: none;
  cursor: pointer;
  border-radius: 1rem;
  width: 2rem !important;
  height: 2rem;
  position: absolute;
  font-size: larger;
  top: -4rem;
  left: 17rem;
  align-items: center;
  justify-content: center !important;
  color: white;
  text-align: center;
  background-color: #0150b5;
  transition: background-color 0.18s;
  padding: unset !important;
}
.ovis-file-label-plus:hover {
  background-color: #0166e8;
}
.ovis-file-label-plus:active {
  background-color: #1e80fe;
}

@media screen and (max-width: 767px) {
  .form.ovis-form .ovis-form-row {
    flex-direction: column;
  }
  .form.ovis-form .ovis-form-label {
    align-items: unset;
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-dashboard {
  overflow-x: hidden;
}
.ovis-dashboard .ovis-dashboard-panel-content {
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  text-align: center;
}
.ovis-dashboard .ovis-dashboard-editor {
  display: flex;
  z-index: 502;
  position: fixed;
  left: calc(100% - 65px);
  transition: transform 0.18s;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-button {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  background: #f5f7fb no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 0.35rem 0 0 0.35rem;
  cursor: pointer;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-button div.ovis-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-button div.ovis-icon svg {
  width: 35px;
  height: calc(65px/ 2);
  stroke: #616771;
  transition: stroke 0.18s cubic-bezier(0, 0, 0.35, 1);
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-button:hover div.ovis-icon svg {
  stroke: #5abada;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  background-color: #f5f7fb;
  border-bottom-left-radius: 0.35em;
  width: 350px;
  padding: 15px;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window h3 {
  font-weight: 400;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-options-button {
  display: flex;
  width: 48%;
  margin: 0.3em 0;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-options-button.--plus {
  margin: 0 0 0 1em;
  width: 2.8em;
  height: 2.5em;
  padding: 0.5em;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-options-button.--plus path:last-child {
  fill: #fff;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-save-select {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-save-select.--top {
  margin-top: 0.5em;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-widget-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-widget-select .ovis-bp-select {
  width: calc(100% - 4em);
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-edit-fields .ovis-input-container input {
  margin-bottom: 0.3em;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-edit-fields .ovis-dashboard-field-label {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-edit-fields .ovis-dashboard-field-label svg {
  cursor: pointer;
  fill: #0e3d7a;
  margin: 0 0.25rem;
  width: 1rem;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-edit-fields .ovis-dashboard-field-label svg:hover {
  fill: #307fc3;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-edit-fields .ovis-checkbox {
  display: flex;
  padding: 0;
  width: 100%;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-edit-fields .ovis-checkbox-group:last-of-type {
  margin-bottom: 1rem;
}
.ovis-dashboard .ovis-dashboard-editor .ovis-dashboard-editor-window .ovis-dashboard-edit-fields > .ovis-checkbox-group > .ovis-checkbox {
  margin-bottom: 0;
}
.ovis-dashboard .ovis-dashboard-editor.open {
  transition: transform 0.18s;
  transform: translateX(-350px);
}
.ovis-dashboard .ovis-dashboard-layout {
  display: grid;
  grid-gap: 20px;
  grid-auto-rows: auto;
  /* grid-template-rows: auto auto; */
}
.ovis-dashboard .ovis-dashboard-layout .ovis-dashboard-panel {
  scrollbar-width: none;
  min-height: 5rem;
  color: #616771;
}
.ovis-dashboard .ovis-dashboard-layout .ovis-dashboard-panel.--editing {
  overflow: hidden;
}
.ovis-dashboard .ovis-dashboard-layout .ovis-dashboard-panel .ovis-dashboard-panel-overlay {
  z-index: 501;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(15, 33, 57, 0.6352941176);
}
.ovis-dashboard .ovis-dashboard-layout .ovis-dashboard-panel .ovis-dashboard-panel-overlay .ovis-dashboard-panel-controls {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  padding: 20px;
}
.ovis-dashboard .ovis-dashboard-layout .ovis-dashboard-panel .ovis-dashboard-panel-overlay .ovis-dashboard-panel-controls .ovis-dashboard-panel-button {
  padding-right: 10px;
}
.ovis-dashboard .ovis-dashboard-layout .ovis-dashboard-panel .ovis-dashboard-panel-overlay .ovis-dashboard-panel-controls .ovis-dashboard-panel-button svg {
  height: 2em;
  width: 2em;
}
.ovis-dashboard .ovis-reporting-table_body {
  width: 100%;
}

.ovis-dashboard_modal {
  border-radius: 0.25rem;
}
.ovis-dashboard_modal .ovis-reporting-table_row td {
  padding: 0.25rem 1rem;
}
.ovis-dashboard_modal.ovis-reporting-table_modal {
  padding-top: 1.5rem;
}
.ovis-dashboard_modal.ovis-reporting-table_modal .resize-button {
  top: 0;
}
.ovis-dashboard_modal.ovis-reporting-chart_modal {
  padding: 1.5rem 1.5rem 1.5rem 0.5rem;
}
.ovis-dashboard_modal-container .ovis-modal_scroll-container {
  align-items: center;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.carousel {
  position: relative;
  background: #fafafa;
  overflow: hidden;
  width: 100%;
  min-height: 300px;
}

.ovis-carousel-instance {
  position: relative;
}

.carousel-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.carousel-slide {
  position: relative;
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}

.carousel-nav {
  position: absolute;
  width: 100%;
  bottom: -10px;
  transform: translate(0, 100%);
  margin: 0;
  padding: 0;
  left: 0%;
  display: flex;
  justify-content: center;
}

.nav-button {
  list-style: none;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  background: #003171;
  border-radius: 100%;
  cursor: pointer;
}
.nav-button.active {
  background: #0150b5;
}

.control {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  margin: 10px;
  cursor: pointer;
  transform: translate(0, -50%);
}
.control.prev {
  left: 0;
  transform: rotate(-180deg) translate(0, 50%);
}
.control.next {
  right: 0;
}
.control:hover svg polygon {
  fill: #0150b5;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.menu ul {
  list-style-type: none;
}
.menu_item, .menu_submenu_title {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.menu_item:before, .menu_item:after, .menu_submenu_title:before, .menu_submenu_title:after {
  display: none;
}
.menu_item:hover, .menu_submenu_title:hover {
  color: rgba(0, 0, 0, 0.5);
}
.menu > ul > .menu_item, .menu_submenu_title {
  padding-left: 0.5rem;
}
.menu_item.-is--submenu {
  padding-left: 0;
}
.menu_submenu p {
  margin: 0;
}
.menu_submenu_items {
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
.menu_submenu_items_container {
  margin-bottom: 0;
  list-style-position: inside;
}
.menu_submenu .menu_item {
  font-size: 0.8rem;
  padding: 0 0.5rem 0 2.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.menu_submenu.--is-open .menu_submenu_title {
  color: rgba(0, 0, 0, 0.5);
}
.menu_submenu.--is-open .expand-icon::before, .menu_submenu.--is-open .expand-icon::after {
  opacity: 1;
}
.menu_submenu.--is-open .expand-icon::before {
  transform: rotate(-45deg);
}
.menu_submenu.--is-open .expand-icon::after {
  transform: rotate(45deg);
}

.expand-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.expand-icon::after, .expand-icon::before {
  position: absolute;
  width: 6px;
  height: 1.5px;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-property: background, transform, top, color, opacity;
  transition-duration: 0.3s, 0.3s, 0.3s, 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1), cubic-bezier(0.645, 0.045, 0.355, 1), cubic-bezier(0.645, 0.045, 0.355, 1), cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s, 0s, 0s, 0s;
  content: "";
  background: black;
  opacity: 0.55;
}
.expand-icon::before {
  left: 0px;
  transform: rotate(45deg);
}
.expand-icon::after {
  right: 0px;
  transform: rotate(-45deg);
}

.menu_icon {
  width: 15px;
  margin-right: 0.25rem;
  height: 15px;
}
.menu_icon svg,
.menu_icon img {
  width: 100%;
  height: 100%;
}
.menu_icon svg > *,
.menu_icon img > * {
  stroke: black;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.ovis-pagination {
  max-width: 30rem;
  margin: 1rem;
}
.ovis-pagination .ovis-pagination-btn {
  background-color: rgb(228, 228, 228);
  border: 1px solid #0150b5;
  height: auto;
  min-height: 1.5rem;
  width: 2rem;
  cursor: pointer;
}
.ovis-pagination .ovis-pagination-goto {
  margin-left: 0.5rem;
}
.ovis-pagination .ovis-pagination-input {
  height: 1.7rem;
  width: 4rem;
}
.ovis-pagination .ovis-pagination-select {
  cursor: pointer;
  margin-left: 0.4rem;
}
.ovis-pagination .ovis-pagination-select_option {
  color: #003171;
}
.ovis-pagination .ovis-pagination-page-counter {
  margin: 0 0.4rem;
}
.clipboard-container {
  cursor: pointer;
}
.clipboard-container.--disabled {
  cursor: default;
  opacity: 0.5;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.side-panel-instance {
  position: fixed;
  top: 3.7rem;
  z-index: 9999;
  width: 100%;
  z-index: 10000;
}
.side-panel-instance.--light .side-panel {
  background: white;
}
.side-panel-instance.--light .side-panel *:after,
.side-panel-instance.--light .side-panel *:before {
  background-color: #30a8d1;
}
.side-panel-instance.--dark .side-panel {
  background: #30a8d1;
}
.side-panel-instance.--dark .side-panel_header {
  background: #30a8d1;
}
.side-panel-instance.--dark * {
  color: white;
}
.side-panel-instance.--small {
  max-width: 375px;
}
.side-panel-instance.--default {
  max-width: 500px;
}
.side-panel-instance.--large {
  max-width: 600px;
}
.side-panel-instance.--right {
  right: 0;
}
.side-panel-instance.--left {
  left: 0;
  border-right: 1px solid #616771;
}
.side-panel-instance.--top, .side-panel-instance.--bottom {
  max-width: 100vw;
}
.side-panel-instance.--top {
  border-bottom: 1px solid #616771;
}
.side-panel-instance.--bottom {
  bottom: 0;
  top: initial;
  border-top: 1px solid #616771;
}

.side-panel {
  background: #30a8d1;
  width: 100%;
  height: calc(100vh - 76px);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
  padding: 1rem;
  padding-top: 98px;
}
.side-panel_wrapper {
  overflow-y: scroll;
  height: 100%;
}
.side-panel_wrapper::-webkit-scrollbar {
  display: none;
}
.side-panel_header {
  position: fixed;
  top: 0;
  background: white;
  width: 100%;
  z-index: 3;
  padding: 1rem 1.5rem 0 0;
}
.side-panel_title {
  width: 100%;
  margin: 0.5rem 0 1rem;
}
.side-panel_title:after {
  content: "";
  display: block;
  position: absolute;
  left: 2px;
  bottom: -0.25rem;
  width: calc(100% + 1rem);
  height: 1px;
  background-color: #0150b5;
}

@media screen and (max-width: 766px) {
  .side-panel-instance {
    top: 3.5rem;
    max-width: initial !important;
    width: 101%;
  }
  .side-panel-instance .side-panel {
    height: calc(100vh - 3.5rem - 2rem - 0.5rem);
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.calender-link {
  font: "Gilroy W05 Regular", sans-serif;
  font-size: 0.875rem;
  font-weight: "Gilroy W05 Regular", sans-serif;
  display: inline;
  margin: 0;
  color: #30a8d1;
  padding-top: 0;
  text-align: left;
  cursor: pointer;
  padding: 0.3rem 0 0;
  width: fit-content;
  transition: color 0.01s ease;
}
.calender-link:hover {
  color: #003171;
}
.profile-photo {
  border-radius: 100%;
  overflow: hidden;
}
.profile-photo > div {
  height: 100%;
}
.profile-photo.--small {
  width: 35px;
  height: 35px;
}
.profile-photo.--medium {
  width: 50px;
  height: 50px;
}
.profile-photo.--large {
  width: 75px;
  height: 75px;
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

/*** Transforms ***/
/*** Positions ***/
/*** Borders and Box shadows ***/
/*** Sizing ***/
/*** Psuedos ***/
/*** Transitions ***/
/*** Appearances ***/
/*** Screen Sizes ***/
.menu-btn {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  margin: 0 0.5rem 0 0;
}
.menu-btn > div {
  width: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 1.5rem;
}
.menu-btn p {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #003171;
  margin-right: 1rem;
}
.menu-btn_bar {
  width: 30px;
  height: 1px;
  background: #003171;
  border-radius: 10px;
  transition: all 0.3s ease;
  position: relative;
  transform-origin: 1px;
}
.menu-btn:focus {
  outline: none;
}
.menu-btn.--is-open .menu-btn_bar:nth-child(1) {
  transform: rotate(45deg);
  width: 32px !important;
  top: -5px;
}
.menu-btn.--is-open .menu-btn_bar:nth-child(2) {
  opacity: 0;
  width: 0;
}
.menu-btn.--is-open .menu-btn_bar:nth-child(3) {
  transform: rotate(-45deg);
  width: 32px !important;
}

@media screen and (max-width: 1199px) {
  .menu-btn {
    height: 3.5rem;
  }
}
@media screen and (max-width: 979px) {
  .menu-btn {
    margin: 0 0.375rem 0 0;
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

.ovis-bp-layout_instance {
  width: 100%;
}
.ovis-bp-layout .col {
  width: 100%;
  display: block;
}
.ovis-bp-layout.--grid {
  display: flex;
  flex-wrap: wrap;
}
.ovis-bp-layout.--grid.--center {
  justify-content: space-between;
}
.ovis-bp-layout.--grid.--center.--fill-2 .col:nth-last-child(1) {
  margin-right: auto;
}
.ovis-bp-layout.--grid.--center.--fill-2 .col:nth-last-child(2) {
  margin-left: auto;
  margin-right: calc(3.0303030303% + 0.5rem);
}
@media (max-width: 979px) {
  .ovis-bp-layout.--grid.--center.--fill-2 .col:nth-last-child(2) {
    margin-left: auto;
    margin-right: calc(2.0408163265% + 0.5rem);
  }
}
.ovis-bp-layout.--grid.--center.--fill-1 .col:nth-last-child(1) {
  margin: auto;
}
.ovis-bp-layout.--grid.--start {
  justify-content: space-between;
}
.ovis-bp-layout.--grid.--start.--fill-2::after {
  width: calc(33.3333333333% - 0.5rem);
  content: "";
}
@media (max-width: 979px) {
  .ovis-bp-layout.--grid.--start.--fill-2 .col:nth-last-child(2) {
    margin-left: auto;
    margin-right: calc(50% - 0.5rem);
  }
}
.ovis-bp-layout.--grid.--start.--fill-1::after {
  flex: auto;
  content: "";
}
@media (max-width: 979px) {
  .ovis-bp-layout.--grid.--start.--fill-1::after {
    display: none;
  }
}
.ovis-bp-layout.--grid.--end {
  justify-content: space-between;
}
.ovis-bp-layout.--grid.--end.--fill-2 .col:nth-last-child(2) {
  margin-left: auto;
  margin-right: calc(3.0303030303% - 0.5rem);
}
.ovis-bp-layout.--grid.--end.--fill-1 .col:nth-last-child(1) {
  margin-left: auto;
}
.ovis-bp-layout.--grid.--col-2 .col {
  width: calc(50% - 0.5rem);
  margin-bottom: 1rem;
}
@media (max-width: 766px) {
  .ovis-bp-layout.--grid.--col-2::after {
    display: none;
  }
  .ovis-bp-layout.--grid.--col-2 .col {
    width: 100%;
  }
}
.ovis-bp-layout.--grid.--col-3 .col {
  width: calc(33.3333333333% - 0.5rem);
  margin-bottom: 1rem;
}
@media (max-width: 979px) {
  .ovis-bp-layout.--grid.--col-3 .col {
    width: calc(50% - 0.5rem);
  }
}
@media (max-width: 766px) {
  .ovis-bp-layout.--grid.--col-3::after {
    display: none;
  }
  .ovis-bp-layout.--grid.--col-3 .col {
    width: 100%;
  }
}
/*** BASE SCALE ***/
/*** FONTS ***/
/* Size */
/* Weight */
/* Line Heights */
/*** Default colors ***/
/*** Sizes ***/
/*** Size resets for global components ***/
/*** Borders ***/
/*** Spacing ***/
/*** Box Shadows ***/
/*** Transitions ***/
/*** z-indexes ***/
/***ANIMATIONS***/
@keyframes slideDown {
  0% {
    top: -250%;
  }
  100% {
    top: 150%;
  }
}
@keyframes slideAcross {
  0% {
    left: -200%;
  }
  100% {
    left: 100%;
  }
}
/* Weights: 

Thin 100
Extra-light 200
Light 300
Regular 400
Medium 500
Semi-bold 600
Bold 700
Extra-bold 800
*/
.--box-shadow-outline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow ease;
}
.--box-shadow-outline.--w-hover:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5254901961);
}

.ovis-bp-card {
  background: white;
  border-radius: 6px;
  min-height: 100%;
  padding: 0.5rem;
}